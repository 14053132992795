import {useEffect, useState} from 'react'
import './index.css';

const Email = () => {
    const [email, setEmail]=useState('');
    const [radio, setRadio] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [doubleEmail, setDoubleEmail] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    const [emailActive, setEmailActive] = useState('email-container-active')
    const [emailClose, setEmailClose] = useState('email-close')

    // const [emailStyle, setEmailStyle] = {
    //   transform: 'translateY(-50px, 0)'
    // };

    async function get_email(email, radio) {
        console.log("did i enter email?", email, radio)
        setEmailValid(isValidEmail(email))
        console.log("isvalid", emailValid)
        if(emailValid == true) {
          const formData = {
            Email: email,
            RadioButton: radio,
            Source: 'www.defigomoon.com', //'defibullion',
            //Date: Date.now()
          };
          // const options = {
          //   method: 'get',
          //   headers: {
          //     "Content-Type": "application/json",
          //     // "x-access-token": "token-value",
          //   }
          // };
          //
          // try {
          //   const response = await fetch('http://localhost:3001/emailList', options);
          //   const data = await response.json();
          //   console.log(data);
          //
          // }
          // catch(error) {
          //   console.log(error);
          // };
          const options = {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
              // "x-access-token": "token-value",
            },
            body: JSON.stringify(formData),
          };

          setEmailSubmitted(true)
    
          try {
            const response = await fetch('https://www.defigomoon.com/emailList', options); //const response = await fetch('http://localhost:3001/emailList', options);
            const data = await response;//.json();
            const dataj = await data.json()
            console.log('data', data, 'datajson', dataj);
            console.log('status', data.status, dataj.status)
            //console.log(dataj)

            if(data.status == 400) {
              if(dataj.message == 'You have allready subscribed!') {
                setDoubleEmail(true)
              }
            }

            if(data.status == 201) {
              setDoubleEmail(false)
            }
    
          }
          catch(error) {
            console.log(error);
          };
    
        }
      }
    
    
      function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

      function closeEmail() {
        if(emailActive == 'email-container-active') {
          setEmailActive('email-container')
          // setEmailClose('email-close-unactive')
        }
      }


    return(
        <div className={emailActive}>
            <div className='email-title'>Defi Go Moon Coming Soon!</div>
            <div className='email-about'>Defi crypto games/services you've never seen before :)</div>
            <div className='email-info'>Get notified when we launch!</div>
            {emailSubmitted ? (<div className='email-submitted'>Thanks for your interest!</div>) :
            <form className='email-form'>
                <input 
                  className='email-input'
                  placeholder='Email'
                  onChange={(e) => {setEmail(e.target.value);}}
                  value={email}
                />
                <input
                  className = "email-radio"
                  type ="radio"
                  onClick={() => setRadio(true)}
                /> 
                
                <button 
                className='email-button'
                type='submit'
                onClick={() => get_email(email, radio)}
                >
                    Sign Up
                </button>
            </form>}
        </div>
    )

}
    export default Email;
