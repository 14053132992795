import './index.css';
import Email from './Email.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div className="main-email-container">
        <Email/>
      </div>
    </div>
  );
}

export default App;
